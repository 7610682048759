<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="ingredientMasterValidation">
      <b-form>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$router.go(-1)"
            >
              <feather-icon icon="SkipBackIcon" /> {{ $t("Common.Back") }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group
              :label="$t('IngredientMainMaster.Ingredient Main Name')"
            >
              <validation-provider
                #default="{ errors }"
                name="Ingredient Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.ingredient_name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('IngredientMainMaster.Ingredient Main Name')"
                  :disabled="!$can('write', 'food')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('IngredientMainMaster.Ingredient Code')">
              <validation-provider
                #default="{ errors }"
                name="Ingredient Code"
                rules="required"
              >
                <b-form-input
                  v-model="form.ingredient_code"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('IngredientMainMaster.Ingredient Code')"
                  :disabled="!$can('write', 'food')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Select Ingredient Master">
              <validation-provider
                #default="{ errors }"
                name="Ingredient Master"
                rules="required"
              >
                <v-select
                  :reduce="(mingredient) => mingredient.mingredient_id"
                  label="mingredient_name"
                  placeholder="Ingredient Master"
                  :state="errors.length > 0 ? false : null"
                  v-model="form.ingredient_mingredient_id"
                  :options="$store.getters['food/getAllIngredientMaster']"
                  :disabled="!$can('write', 'food')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('IngredientMainMaster.Country Name')">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <v-select
                  :reduce="(country) => country.country_id"
                  label="country_name"
                  :placeholder="$t('IngredientMainMaster.Country Name')"
                  v-model="form.ingredient_country_id"
                  :options="$store.getters['masters/getAllCountry']"
                  :disabled="!$can('write', 'food')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('IngredientMainMaster.Language Name')">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <v-select
                  :reduce="(language) => language.language_id"
                  label="language_name"
                  :placeholder="$t('IngredientMainMaster.Language Name')"
                  v-model="form.ingredient_lang_id"
                  :options="$store.getters['masters/getAllLanguage']"
                  :disabled="!$can('write', 'food')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('IngredientMainMaster.Ingredient Category')"
            >
              <validation-provider
                #default="{ errors }"
                name="Ingredient Category"
                rules="required"
              >
                <v-select
                  :reduce="
                    (AllIngredientCategory) =>
                      AllIngredientCategory.ingcategory_id
                  "
                  label="ingcategory_name"
                  :placeholder="$t('IngredientMainMaster.Ingredient Category')"
                  v-model="form.ingredient_ingcategory_id"
                  :options="$store.getters['food/getAllIngredientCategory']"
                  :disabled="!$can('write', 'food')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="'Base Unit'">
              <!-- <validation-provider
                #default="{ errors }"
                name="Base Unit"
                rules="required"
              > -->
              <v-select
                :reduce="(AllLangUnit) => AllLangUnit.langunit_id"
                label="langunit_name"
                v-model="form.ingredient_base_unit_id"
                :options="$store.getters['masters/getAllLangUnit']"
                :disabled="!$can('write', 'food')"
                placeholder="Select Unit"
              />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="'Base Quantity'">
              <!-- <validation-provider
                #default="{ errors }"
                name="Base Quantity"
                rules="required"
              > -->
              <b-form-input
                v-model="form.ingredient_base_quantity"
                type="number"
                :placeholder="'Base Quantity'"
                :disabled="!$can('write', 'food')"
              />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="'Budget Price'">
              <validation-provider
                #default="{ errors }"
                name="Budget Price"
                rules="twoDigitsAfterDecimal"
              >
                <b-form-input
                  v-model="form.ingredient_budget"
                  type="number"
                  :placeholder="'Budget Price'"
                  :disabled="!$can('write', 'food')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="'Regular Price'">
              <validation-provider
                #default="{ errors }"
                name="Regular Price"
                rules="twoDigitsAfterDecimal"
              >
                <b-form-input
                  v-model="form.ingredient_regular"
                  type="number"
                  :placeholder="'Regular Price'"
                  :disabled="!$can('write', 'food')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="'Premium Price'">
              <validation-provider
                #default="{ errors }"
                name="Premium Price"
                rules="twoDigitsAfterDecimal"
              >
                <b-form-input
                  v-model="form.ingredient_premium"
                  type="number"
                  :placeholder="'Premium Price'"
                  :disabled="!$can('write', 'food')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <image-upload-with-cropper
              v-model="form.ingredient_image_file"
              :width="64"
              :height="65"
              imagetype="recipe"
              label="Upload image"
            />
          </b-col>
          <b-col cols="12" class="text-center">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="saveIngredientMaster"
            >
              {{ $t("Common.Save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageUploadWithCropper from "@/views/components/ImageUploadWithCropper.vue";
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import { required, twoDigitsAfterDecimal } from "@validations";
import FoodServices from "@/apiServices/FoodServices";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "IngredientForm",
  directives: {
    Ripple,
  },
  components: {
    ImageUploadWithCropper,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormFile,
    VueCropper,
  },
  data() {
    return {
      cropImg: "",
      ingredient_id: null,
      form: {
        ingredient_name: "",
        ingredient_code: "",
        ingredient_mingredient_id: "",
        ingredient_country_id: "",
        ingredient_lang_id: "",
        ingredient_ingcategory_id: "",
        ingredient_image_file: null,
        ingredient_base_unit_id: null,
        ingredient_base_quantity: null,
        ingredient_budget: null,
        ingredient_regular: null,
        ingredient_premium: null,
        ingredient_is_active: true,
      },
      required,
      twoDigitsAfterDecimal,
    };
  },

  methods: {
    saveIngredientMaster() {
      this.$refs.ingredientMasterValidation.validate().then(async (success) => {
        if (success) {
          try {
            const ingredientMaster = await FoodServices.saveIngredientMain(
              this.form
            );

            if (ingredientMaster.data.status) {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title:
                    ingredientMaster.data.message ||
                    "Ingredient Added Successfull",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.replace("/food/ingredient-main-list");
            } else {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title:
                    ingredientMaster.data.message || "Cannot Added Ingredient",
                  icon: "EditIcon",
                  variant: "failure",
                },
              });
            }
          } catch (error) {
            console.log("Error in saveIngredient ", error);
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: error.data.message || "Error in saveIngredient",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Fill all Mandatory feilds",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      });
    },
    async getSingleIngredientMaster(ingredient_id) {
      try {
        const response = await FoodServices.getSingleIngredientMain(
          ingredient_id
        );
        if (response.data.status) {
          this.form = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
  beforeMount() {
    this.$store.dispatch("masters/setCountry");
    this.$store.dispatch("masters/setLanguage");
    this.$store.dispatch("masters/setLangUnit");
    this.$store.dispatch("food/setIngredientMaster");
    this.$store.dispatch("food/setIngredientCategoryMaster");
    if (
      this.$route.params.ingredient_id &&
      !isNaN(this.$route.params.ingredient_id)
    ) {
      this.ingredient_id = this.$route.params.ingredient_id;
      this.getSingleIngredientMaster(this.ingredient_id);
    }
  },
};
</script>

<style></style>
