var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"ingredientMasterValidation"},[_c('b-form',[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('feather-icon',{attrs:{"icon":"SkipBackIcon"}}),_vm._v(" "+_vm._s(_vm.$t("Common.Back"))+" ")],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('IngredientMainMaster.Ingredient Main Name')}},[_c('validation-provider',{attrs:{"name":"Ingredient Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"text","placeholder":_vm.$t('IngredientMainMaster.Ingredient Main Name'),"disabled":!_vm.$can('write', 'food')},model:{value:(_vm.form.ingredient_name),callback:function ($$v) {_vm.$set(_vm.form, "ingredient_name", $$v)},expression:"form.ingredient_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('IngredientMainMaster.Ingredient Code')}},[_c('validation-provider',{attrs:{"name":"Ingredient Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"text","placeholder":_vm.$t('IngredientMainMaster.Ingredient Code'),"disabled":!_vm.$can('write', 'food')},model:{value:(_vm.form.ingredient_code),callback:function ($$v) {_vm.$set(_vm.form, "ingredient_code", $$v)},expression:"form.ingredient_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Select Ingredient Master"}},[_c('validation-provider',{attrs:{"name":"Ingredient Master","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (mingredient) { return mingredient.mingredient_id; },"label":"mingredient_name","placeholder":"Ingredient Master","state":errors.length > 0 ? false : null,"options":_vm.$store.getters['food/getAllIngredientMaster'],"disabled":!_vm.$can('write', 'food')},model:{value:(_vm.form.ingredient_mingredient_id),callback:function ($$v) {_vm.$set(_vm.form, "ingredient_mingredient_id", $$v)},expression:"form.ingredient_mingredient_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('IngredientMainMaster.Country Name')}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (country) { return country.country_id; },"label":"country_name","placeholder":_vm.$t('IngredientMainMaster.Country Name'),"options":_vm.$store.getters['masters/getAllCountry'],"disabled":!_vm.$can('write', 'food')},model:{value:(_vm.form.ingredient_country_id),callback:function ($$v) {_vm.$set(_vm.form, "ingredient_country_id", $$v)},expression:"form.ingredient_country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('IngredientMainMaster.Language Name')}},[_c('validation-provider',{attrs:{"name":"Language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (language) { return language.language_id; },"label":"language_name","placeholder":_vm.$t('IngredientMainMaster.Language Name'),"options":_vm.$store.getters['masters/getAllLanguage'],"disabled":!_vm.$can('write', 'food')},model:{value:(_vm.form.ingredient_lang_id),callback:function ($$v) {_vm.$set(_vm.form, "ingredient_lang_id", $$v)},expression:"form.ingredient_lang_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('IngredientMainMaster.Ingredient Category')}},[_c('validation-provider',{attrs:{"name":"Ingredient Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (AllIngredientCategory) { return AllIngredientCategory.ingcategory_id; },"label":"ingcategory_name","placeholder":_vm.$t('IngredientMainMaster.Ingredient Category'),"options":_vm.$store.getters['food/getAllIngredientCategory'],"disabled":!_vm.$can('write', 'food')},model:{value:(_vm.form.ingredient_ingcategory_id),callback:function ($$v) {_vm.$set(_vm.form, "ingredient_ingcategory_id", $$v)},expression:"form.ingredient_ingcategory_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Base Unit'}},[_c('v-select',{attrs:{"reduce":function (AllLangUnit) { return AllLangUnit.langunit_id; },"label":"langunit_name","options":_vm.$store.getters['masters/getAllLangUnit'],"disabled":!_vm.$can('write', 'food'),"placeholder":"Select Unit"},model:{value:(_vm.form.ingredient_base_unit_id),callback:function ($$v) {_vm.$set(_vm.form, "ingredient_base_unit_id", $$v)},expression:"form.ingredient_base_unit_id"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Base Quantity'}},[_c('b-form-input',{attrs:{"type":"number","placeholder":'Base Quantity',"disabled":!_vm.$can('write', 'food')},model:{value:(_vm.form.ingredient_base_quantity),callback:function ($$v) {_vm.$set(_vm.form, "ingredient_base_quantity", $$v)},expression:"form.ingredient_base_quantity"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":'Budget Price'}},[_c('validation-provider',{attrs:{"name":"Budget Price","rules":"twoDigitsAfterDecimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":'Budget Price',"disabled":!_vm.$can('write', 'food')},model:{value:(_vm.form.ingredient_budget),callback:function ($$v) {_vm.$set(_vm.form, "ingredient_budget", $$v)},expression:"form.ingredient_budget"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":'Regular Price'}},[_c('validation-provider',{attrs:{"name":"Regular Price","rules":"twoDigitsAfterDecimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":'Regular Price',"disabled":!_vm.$can('write', 'food')},model:{value:(_vm.form.ingredient_regular),callback:function ($$v) {_vm.$set(_vm.form, "ingredient_regular", $$v)},expression:"form.ingredient_regular"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":'Premium Price'}},[_c('validation-provider',{attrs:{"name":"Premium Price","rules":"twoDigitsAfterDecimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":'Premium Price',"disabled":!_vm.$can('write', 'food')},model:{value:(_vm.form.ingredient_premium),callback:function ($$v) {_vm.$set(_vm.form, "ingredient_premium", $$v)},expression:"form.ingredient_premium"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('image-upload-with-cropper',{attrs:{"width":64,"height":65,"imagetype":"recipe","label":"Upload image"},model:{value:(_vm.form.ingredient_image_file),callback:function ($$v) {_vm.$set(_vm.form, "ingredient_image_file", $$v)},expression:"form.ingredient_image_file"}})],1),_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.saveIngredientMaster.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("Common.Save"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }